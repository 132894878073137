<template>
  <LookupEditor
    lookup-service="stories-service/livsmestring"
    lookup-type="fag"
  />
</template>

<script>
import LookupEditor from '../../components/lookups/LookupEditor';
export default {
  components: {
    LookupEditor,
  },
};
</script>
